import { Renderer as MarkedRenderer } from 'marked';
import * as types from './types.ts';

export function getRenderer(options: types.IMarkdownOptions) {
  const renderer = new MarkedRenderer();

  // Custom renderers extend marked versions.
  if (options.linksInNewTab) {
    renderer.link = renderLink;
  }
  if (options.imageDimensions) {
    renderer.image = renderImage;
  }

  return renderer;
}

/**
 * Link renderer
 *
 * Extends `marked` implementation with new option `linksInNewTab`.
 */
const renderLink: types.LinkRenderer = function (href, title, text) {
  if (this.options.sanitize) {
    let prot;
    try {
      prot = decodeURIComponent(unescape(href))
        .replace(/[^\w:]/g, '')
        .toLowerCase();
    } catch (e) {
      return text;
    }
    if (
      prot.indexOf('javascript:') === 0 ||
      prot.indexOf('vbscript:') === 0 ||
      prot.indexOf('data:') === 0
    ) {
      return text;
    }
  }
  if (this.options.baseUrl && !originIndependentUrl.test(href)) {
    href = resolveUrl(this.options.baseUrl, href);
  }
  try {
    href = encodeURI(href).replace(/%25/g, '%');
  } catch (e) {
    return text;
  }
  let out = '<a href="' + escape(href) + '"';
  if (title) {
    out += ' title="' + title + '"';
  }
  if (this.options.linksInNewTab) {
    out += ' target="_blank"';
  }
  out += '>' + text + '</a>';
  return out;
};

/**
 * Image renderer.
 *
 * Extends `marked` implementation with size info in title field.
 *
 * e.g. ![Alt Text](src.png "{200x100} My Title") describes an image with:
 * src = src.png
 * alt-text = 'Alt Text'
 * title = 'My Title'
 * width = 200px
 * height = 100px
 *
 * Valid size strings also include "{x100}", "{200X100}", "{200*100}",
 * & "{100px*200px}".
 *
 * N.B. This is crude & non-standard. Ideally extend lexer and/or use
 * attribute-list format from Kramdown (or similar.)
 */
const renderImage: types.LinkRenderer = function (href, title, text) {
  if (this.options.baseUrl && !originIndependentUrl.test(href)) {
    href = resolveUrl(this.options.baseUrl, href);
  }

  const { width, title: cleanTitle } = parseImageTitle(title);

  const widthAsNumber = width !== undefined ? Number(width) : undefined;
  const imageWidth =
    widthAsNumber && widthAsNumber < 650 ? `${widthAsNumber}px` : '100%';

  let out = `<img src="${href}" alt="${text}" width="${imageWidth}"`;
  if (cleanTitle) {
    out += ' title="' + cleanTitle + '"';
  }
  out += this.options.xhtml ? '/>' : '>';
  return out;
};

const parseImageTitle = (title: string) => {
  const dimensions = title?.match(/^{(\d*)(?:px)*[xX*]*(\d*)(?:px)*}\s*(.*)/);

  const safeGroup = (i: number, defaultVal?: string) =>
    (dimensions && dimensions[i]) ?? defaultVal;

  return {
    width: safeGroup(1),
    height: safeGroup(2),
    title: safeGroup(3, title),
  };
};

/**
 * Helpers.
 *
 * Directly copied from marked since they're not available externally.
 * We're not customizing these methods and only copied them to keep our custom
 * renderers as close to the original as possible.
 *
 */

function escape(html: any, encode?: any) {
  return html
    .replace(!encode ? /&(?!#?\w+;)/g : /&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
}

function unescape(html: string) {
  // explicitly match decimal, hex, and named HTML entities
  return html.replace(
    /&(#(?:\d+)|(?:#x[0-9A-Fa-f]+)|(?:\w+));?/gi,
    function (_, n) {
      n = n.toLowerCase();
      if (n === 'colon') return ':';
      if (n.charAt(0) === '#') {
        return n.charAt(1) === 'x'
          ? String.fromCharCode(parseInt(n.substring(2), 16))
          : String.fromCharCode(+n.substring(1));
      }
      return '';
    },
  );
}

function resolveUrl(base: any, href: any) {
  if (!baseUrls[' ' + base]) {
    // we can ignore everything in base after the last slash of its path component,
    // but we might need to add _that_
    // https://tools.ietf.org/html/rfc3986#section-3
    if (/^[^:]+:\/*[^/]*$/.test(base)) {
      baseUrls[' ' + base] = base + '/';
    } else {
      baseUrls[' ' + base] = rtrim(base, '/', true);
    }
  }
  base = baseUrls[' ' + base];

  if (href.slice(0, 2) === '//') {
    return base.replace(/:[\s\S]*/, ':') + href;
  } else if (href.charAt(0) === '/') {
    return base.replace(/(:\/*[^/]*)[\s\S]*/, '$1') + href;
  } else {
    return base + href;
  }
}
const baseUrls: any = {};
const originIndependentUrl = /^$|^[a-z][a-z0-9+.-]*:|^[?#]/i;

// Remove trailing 'c's. Equivalent to str.replace(/c*$/, '').
// /c*$/ is vulnerable to REDOS.
// invert: Remove suffix of non-c chars instead. Default falsey.
function rtrim(str: any, c: any, invert: any) {
  if (str.length === 0) {
    return '';
  }

  // Length of suffix matching the invert condition.
  let suffLen = 0;

  // Step left until we fail to match the invert condition.
  while (suffLen < str.length) {
    const currChar = str.charAt(str.length - suffLen - 1);
    if (currChar === c && !invert) {
      suffLen++;
    } else if (currChar !== c && invert) {
      suffLen++;
    } else {
      break;
    }
  }

  return str.substr(0, str.length - suffLen);
}
