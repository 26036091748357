/** @jsxImportSource @emotion/react */
import { Icons } from '../../Icon/Icons.tsx';
import { IconButton } from '../../IconButton/IconButton.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css, SerializedStyles } from '@emotion/react';

export interface INavButtonProps {
  type: 'PREVIOUS' | 'NEXT';
  color: number | string;
  style?: SerializedStyles;
  onClick?: (e: {}) => void;
  tabIndex?: number;
}

export const NavButton = (props: INavButtonProps) => {
  const fill = color.format(props.color);
  const Icon = props.type === 'NEXT' ? Icons.chevronRight : Icons.chevronLeft;

  const elIcon = <Icon />;

  return (
    <div css={[styles.base, props.style]}>
      <IconButton
        icon={elIcon}
        fill={fill}
        onClick={props.onClick}
        tabIndex={props.tabIndex}
      />
    </div>
  );
};
const styles = {
  base: css({
    position: 'relative',
    top: 1,
  }),
};
