/** @jsxImportSource @emotion/react */
import React from 'react';
import { markdown } from '@seeeverything/ui.util/src/markdown/markdown.ts';

/**
 * Markdown into DOM element.
 */
export function formatMarkdown(children: React.ReactNode) {
  if (typeof children !== 'string') {
    return children;
  }
  const text = children.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  const html = markdown(text, { linksInNewTab: true });
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
