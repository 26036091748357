/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconButton, SvgIcon } from '@material-ui/core';
import React from 'react';
import { IIconInternalProps, IIconProps } from './types.ts';
import { getTransform } from './util.ts';

import AccessTime from '@material-ui/icons/AccessTime.js';
import AccountBalance from '@material-ui/icons/AccountBalance.js';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet.js';
import AccountCircle from '@material-ui/icons/AccountCircle.js';
import Add from '@material-ui/icons/Add.js';
import AddAlert from '@material-ui/icons/AddAlert.js';
import AddBox from '@material-ui/icons/AddBox.js';
import AddCircle from '@material-ui/icons/AddCircle.js';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline.js';
import AddComment from '@material-ui/icons/AddComment.js';
import ArrowBack from '@material-ui/icons/ArrowBack.js';
import ArrowDownward from '@material-ui/icons/ArrowDownward.js';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown.js';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp.js';
import ArrowForward from '@material-ui/icons/ArrowForward.js';
import ArrowUpward from '@material-ui/icons/ArrowUpward.js';
import Assignment from '@material-ui/icons/Assignment.js';
import AssignmentInd from '@material-ui/icons/AssignmentInd.js';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn.js';
import AvTimer from '@material-ui/icons/AvTimer.js';
import BarChart from '@material-ui/icons/BarChart.js';
import Book from '@material-ui/icons/Book.js';
import Bookmark from '@material-ui/icons/Bookmark.js';
import BubbleChart from '@material-ui/icons/BubbleChart.js';
import Build from '@material-ui/icons/Build.js';
import BusinessCenter from '@material-ui/icons/BusinessCenter.js';
import Cancel from '@material-ui/icons/Cancel.js';
import Category from '@material-ui/icons/Category.js';
import ChatBubble from '@material-ui/icons/ChatBubble.js';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline.js';
import Check from '@material-ui/icons/Check.js';
import CheckBox from '@material-ui/icons/CheckBox.js';
import CheckCircle from '@material-ui/icons/CheckCircle.js';
import ChevronLeft from '@material-ui/icons/ChevronLeft.js';
import ChevronRight from '@material-ui/icons/ChevronRight.js';
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode.js';
import Clear from '@material-ui/icons/Clear.js';
import CloudDone from '@material-ui/icons/CloudDone.js';
import CloudDownload from '@material-ui/icons/CloudDownload.js';
import CloudUpload from '@material-ui/icons/CloudUpload.js';
import Collections from '@material-ui/icons/Collections.js';
import CropOriginal from '@material-ui/icons/CropOriginal.js';
import Dashboard from '@material-ui/icons/Dashboard.js';
import DateRange from '@material-ui/icons/DateRange.js';
import Delete from '@material-ui/icons/Delete.js';
import Description from '@material-ui/icons/Description.js';
import DeviceHub from '@material-ui/icons/DeviceHub.js';
import Done from '@material-ui/icons/Done.js';
import DoneAll from '@material-ui/icons/DoneAll.js';
import Drafts from '@material-ui/icons/Drafts.js';
import Eco from '@material-ui/icons/Eco.js';
import Edit from '@material-ui/icons/Edit.js';
import Email from '@material-ui/icons/Email.js';
import ErrorOutline from '@material-ui/icons/ErrorOutline.js';
import Face from '@material-ui/icons/Face.js';
import Feedback from '@material-ui/icons/Feedback.js';
import FileCopy from '@material-ui/icons/FileCopy.js';
import FilterList from '@material-ui/icons/FilterList.js';
import Fingerprint from '@material-ui/icons/Fingerprint.js';
import FlashOn from '@material-ui/icons/FlashOn.js';
import Flight from '@material-ui/icons/Flight.js';
import FontDownload from '@material-ui/icons/FontDownload.js';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted.js';
import Gavel from '@material-ui/icons/Gavel.js';
import GetApp from '@material-ui/icons/GetApp.js';
import Group from '@material-ui/icons/Group.js';
import HighlightOff from '@material-ui/icons/HighlightOff.js';
import Home from '@material-ui/icons/Home.js';
import HowToReg from '@material-ui/icons/HowToReg.js';
import Image from '@material-ui/icons/Image.js';
import Info from '@material-ui/icons/Info.js';
import InsertPhoto from '@material-ui/icons/InsertPhoto.js';
import Keyboard from '@material-ui/icons/Keyboard.js';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown.js';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp.js';
import Landscape from '@material-ui/icons/Landscape.js';
import Launch from '@material-ui/icons/Launch.js';
import Lens from '@material-ui/icons/Lens.js';
import Link from '@material-ui/icons/Link.js';
import List from '@material-ui/icons/List.js';
import LocalLibrary from '@material-ui/icons/LocalLibrary.js';
import LocationCity from '@material-ui/icons/LocationCity.js';
import LocationOn from '@material-ui/icons/LocationOn.js';
import Lock from '@material-ui/icons/Lock.js';
import LockOpen from '@material-ui/icons/LockOpen.js';
import MailOutline from '@material-ui/icons/MailOutline.js';
import MeetingRoom from '@material-ui/icons/MeetingRoom.js';
import Menu from '@material-ui/icons/Menu.js';
import MenuBook from '@material-ui/icons/MenuBook.js';
import Message from '@material-ui/icons/Message.js';
import MoreVert from '@material-ui/icons/MoreVert.js';
import Mouse from '@material-ui/icons/Mouse.js';
import Movie from '@material-ui/icons/Movie.js';
import MyLocation from '@material-ui/icons/MyLocation.js';
import NetworkCheck from '@material-ui/icons/NetworkCheck.js';
import Notifications from '@material-ui/icons/Notifications.js';
import Person from '@material-ui/icons/Person.js';
import PersonOutline from '@material-ui/icons/PersonOutline.js';
import Phone from '@material-ui/icons/Phone.js';
import PhoneIphone from '@material-ui/icons/PhoneIphone.js';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf.js';
import PlayArrow from '@material-ui/icons/PlayArrow.js';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck.js';
import Poll from '@material-ui/icons/Poll.js';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew.js';
import Public from '@material-ui/icons/Public.js';
import Publish from '@material-ui/icons/Publish.js';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked.js';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked.js';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver.js';
import Redo from '@material-ui/icons/Redo.js';
import Refresh from '@material-ui/icons/Refresh.js';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye.js';
import Satellite from '@material-ui/icons/Satellite.js';
import Save from '@material-ui/icons/Save.js';
import ScatterPlot from '@material-ui/icons/ScatterPlot.js';
import Search from '@material-ui/icons/Search.js';
import Send from '@material-ui/icons/Send.js';
import Settings from '@material-ui/icons/Settings.js';
import ShowChart from '@material-ui/icons/ShowChart.js';
import Sort from '@material-ui/icons/Sort.js';
import Star from '@material-ui/icons/Star.js';
import Stop from '@material-ui/icons/Stop.js';
import Store from '@material-ui/icons/Store.js';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount.js';
import SwapHoriz from '@material-ui/icons/SwapHoriz.js';
import SwapVert from '@material-ui/icons/SwapVert.js';
import TextFields from '@material-ui/icons/TextFields.js';
import TextFormat from '@material-ui/icons/TextFormat.js';
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown.js';
import Today from '@material-ui/icons/Today.js';
import TrackChanges from '@material-ui/icons/TrackChanges.js';
import TrendingUp from '@material-ui/icons/TrendingUp.js';
import Undo from '@material-ui/icons/Undo.js';
import Update from '@material-ui/icons/Update.js';
import VerifiedUser from '@material-ui/icons/VerifiedUser.js';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline.js';
import ViewWeek from '@material-ui/icons/ViewWeek.js';
import VisibilityOff from '@material-ui/icons/VisibilityOff.js';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined.js';
import Warning from '@material-ui/icons/Warning.js';
import Waves from '@material-ui/icons/Waves.js';
import Web from '@material-ui/icons/Web.js';
import Work from '@material-ui/icons/Work.js';
import Animation from '@mui/icons-material/Animation.js';
import Article from '@mui/icons-material/Article.js';
import AutoGraphIcon from '@mui/icons-material/AutoGraph.js';
import ContactSupport from '@mui/icons-material/ContactSupport.js';
import ContentCopy from '@mui/icons-material/ContentCopy.js';
import DeleteOutline from '@mui/icons-material/DeleteOutline.js';
import DesktopAccessDisabled from '@mui/icons-material/DesktopAccessDisabled.js';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown.js';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined.js';
import FilterListOff from '@mui/icons-material/FilterListOff.js';
import HelpOutline from '@mui/icons-material/HelpOutline.js';
import HistoryIcon from '@mui/icons-material/History.js';
import LogoutIcon from '@mui/icons-material/Logout.js';
import Policy from '@mui/icons-material/Policy.js';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer.js';
import RemoveModerator from '@mui/icons-material/RemoveModerator.js';
import ReviewsIcon from '@mui/icons-material/Reviews.js';
import SafetyCheck from '@mui/icons-material/SafetyCheck.js';
import Security from '@mui/icons-material/Security.js';
import SelectAllIcon from '@mui/icons-material/SelectAll.js';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle.js';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates.js';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

const MaterialIcon = (props: IIconInternalProps & { Icon?: any }) => {
  const {
    Icon = SvgIcon,
    fill = COLORS.BLUE,
    size = 24,
    isFlippedX = false,
    isFlippedY = false,
    rotate,
    cursor,
    style,
    onClick,
    title,
    children,
    tooltip,
  } = props;

  const elIcon = (
    <Icon
      style={{
        fill,
        fontSize: size,
        transform: getTransform(isFlippedX, isFlippedY, rotate),
        cursor,
        ...style,
      }}
    >
      {children}
    </Icon>
  );

  const elTooltippedIcon = tooltip ? (
    <Tooltip title={tooltip}>{elIcon}</Tooltip>
  ) : (
    elIcon
  );

  return onClick ? (
    <div
      css={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size,
        width: size,
      })}
    >
      <IconButton aria-label={title} onClick={onClick}>
        {elTooltippedIcon}
      </IconButton>
    </div>
  ) : (
    elTooltippedIcon
  );
};

const standard = (icon: any, propOverrides?: Partial<IIconProps>) =>
  function Standard(props: IIconProps) {
    return <MaterialIcon {...props} {...propOverrides} Icon={icon} />;
  };

export const Icons: { [key: string]: React.FC<IIconProps> } = {
  // Custom.
  dot: (props) => (
    <MaterialIcon {...props}>
      <path
        d={
          'm 9 13 a -3 -3 0 0 1 3 -3 a -3 -3 0 0 1 3 3 a -3 -3 0 0 1 -3 3 a -3 -3 0 0 1 -3 -3'
        }
      />
    </MaterialIcon>
  ),

  bulbAndSparkles: (props) => (
    <MaterialIcon {...props}>
      <path
        d={
          'M10.1862 19.1937H10.0862V19.2937C10.0862 20.2383 10.842 21.0145 11.771 21.0145C12.6999 21.0145 13.4557 20.2383 13.4557 19.2937V19.1937H13.3557H10.1862ZM8.50146 18.4833V18.5833H8.60146H14.9405H15.0405V18.4833V16.8626V16.7626H14.9405H8.60146H8.50146V16.8626V18.4833ZM14.7266 16.1522H14.7539L14.7774 16.1383C15.6706 15.6116 17.8138 13.9316 17.8138 10.7846C17.8138 7.37653 15.1088 4.60672 11.771 4.60672C8.43319 4.60672 5.72812 7.37653 5.72812 10.7846C5.72812 13.9316 7.87138 15.6116 8.76461 16.1383L8.78811 16.1522H8.8154H14.7266ZM20.4446 9.65955L21.4967 10.1543L21.9807 11.2308L22.0719 11.4337L22.1631 11.2308L22.6472 10.1543L23.6993 9.65955L23.8917 9.56905L23.6993 9.47856L22.6472 8.98376L22.1631 7.90727L22.0719 7.70442L21.9807 7.90727L21.4967 8.98376L20.4446 9.47856L20.2522 9.56905L20.4446 9.65955ZM19.6035 7.98902L19.6948 8.1937L19.7861 7.98902L20.5157 6.35371L22.1142 5.60772L22.3084 5.51711L22.1142 5.42649L20.5157 4.68051L19.7861 3.04519L19.6948 2.84051L19.6035 3.04519L18.8738 4.68051L17.2754 5.42649L17.0812 5.51711L17.2754 5.60772L18.8738 6.35371L19.6035 7.98902ZM11.771 6.4275C14.1146 6.4275 16.0291 8.38226 16.0291 10.7846C16.0291 12.7163 14.9057 13.8368 14.2343 14.3314H9.30764C8.63629 13.8368 7.51289 12.7163 7.51289 10.7846C7.51289 8.38226 9.4274 6.4275 11.771 6.4275Z'
        }
      />
    </MaterialIcon>
  ),

  graphArea: (props) => (
    <MaterialIcon {...props}>
      <path d={'M2.66666 16L7.33333 9.33333L12 16H2.66666Z'} />
      <path d={'M9.33333 16L15.3333 8L21.3333 16H9.33333Z'} />
    </MaterialIcon>
  ),

  pin: (props) => (
    <MaterialIcon {...props}>
      <path
        d={
          'M6,-0.5l12,0c0,0 -0.388,1.953 -1,2.5c-0.699,0.625 -2,1 -2,1l0,11c0,0 1.692,-0.048 3,1c1.209,0.969 2.131,1.947 2.5,2.5c0.292,0.439 0.5,1.5 0.5,1.5l-7,0l-1,5.5l-2,0l-1,-5.5l-7,0c0,0 0.19,-1.074 0.5,-1.5c0.421,-0.58 1.235,-1.489 2.5,-2.5c1.226,-0.98 3,-1 3,-1l0,-11c0,0 -1.553,-0.404 -2,-1c-0.5,-0.667 -1,-2.5 -1,-2.5Z'
        }
      />
    </MaterialIcon>
  ),

  factCheck: (props) => (
    <MaterialIcon {...props}>
      <path
        d={
          'M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M10,17H5v-2h5V17z M10,13H5v-2h5V13z M10,9H5V7h5V9z M14.82,15L12,12.16l1.41-1.41l1.41,1.42L17.99,9 l1.42,1.42L14.82,15z'
        }
      />
    </MaterialIcon>
  ),

  collapseCircle: (props) => (
    <Icons.expandCircleDownIcon {...props} rotate={90} />
  ),
  collapseCircleOutlined: (props) => (
    <Icons.expandCircleDownOutlinedIcon {...props} rotate={90} />
  ),
  expandCircle: (props) => (
    <Icons.expandCircleDownIcon {...props} rotate={-90} />
  ),
  expandCircleOutlined: (props) => (
    <Icons.expandCircleDownOutlinedIcon {...props} rotate={-90} />
  ),
  unpinned: (props) => <Icons.pin {...props} rotate={45} />,

  // Customized from Material-UI
  clearCircle: standard(AddCircle, { rotate: 45 }),
  sortAscending: standard(Sort, { isFlippedY: true }),
  sortDescending: standard(Sort),

  // Backwards compatibility
  actionList: standard(PlaylistAddCheck),
  arrowDown: standard(ArrowDownward),
  arrowUp: standard(ArrowUpward),
  autoGraph: standard(AutoGraphIcon),
  clipboardList: standard(Assignment),
  clipboardPerson: standard(AssignmentInd),
  clipboardTick: standard(AssignmentTurnedIn),
  clock: standard(AccessTime),
  company: standard(AccountBalance),
  document: standard(Description),
  editPencil: standard(Edit),
  emailOutline: standard(MailOutline),
  eye: standard(RemoveRedEye),
  eyeOutlined: standard(VisibilityOutlined),
  fileDownload: standard(GetApp),
  filter: standard(FilterList),
  infoCard: standard(ChromeReaderMode),
  iPhone: standard(PhoneIphone),
  plus: standard(AddCircleOutline),
  portfolio: standard(Work),
  power: standard(PowerSettingsNew),
  speedo: standard(AvTimer),
  swapHorizontal: standard(SwapHoriz),
  swapVertical: standard(SwapVert),
  switchAccount: standard(SupervisorAccount),
  team: standard(SupervisorAccount),
  tickDone: standard(Done),
  wallet: standard(AccountBalanceWallet),

  // Standard from Material-ui
  accessTime: standard(AccessTime),
  accountBalance: standard(AccountBalance),
  accountBalanceWallet: standard(AccountBalanceWallet),
  accountCircle: standard(AccountCircle),
  add: standard(Add),
  addAlert: standard(AddAlert),
  addBox: standard(AddBox),
  addCircle: standard(AddCircle),
  addCircleOutline: standard(AddCircleOutline),
  addComment: standard(AddComment),
  animation: standard(Animation),
  arrowBack: standard(ArrowBack),
  arrowDownward: standard(ArrowDownward),
  arrowDropDown: standard(ArrowDropDown),
  arrowDropUp: standard(ArrowDropUp),
  arrowForward: standard(ArrowForward),
  arrowUpward: standard(ArrowUpward),
  article: standard(Article),
  assignment: standard(Assignment),
  assignmentInd: standard(AssignmentInd),
  assignmentTurnedIn: standard(AssignmentTurnedIn),
  avTimer: standard(AvTimer),
  barChart: standard(BarChart),
  book: standard(Book),
  bookmark: standard(Bookmark),
  bubbleChart: standard(BubbleChart),
  build: standard(Build),
  businessCenter: standard(BusinessCenter),
  cancel: standard(Cancel),
  category: standard(Category),
  chatBubble: standard(ChatBubble),
  chatBubbleOutline: standard(ChatBubbleOutline),
  check: standard(Check),
  checkBox: standard(CheckBox),
  checkCircle: standard(CheckCircle),
  chevronLeft: standard(ChevronLeft),
  chevronRight: standard(ChevronRight),
  chromeReaderMode: standard(ChromeReaderMode),
  clear: standard(Clear),
  cloudDone: standard(CloudDone),
  cloudDownload: standard(CloudDownload),
  cloudUpload: standard(CloudUpload),
  collections: standard(Collections),
  contactSupport: standard(ContactSupport),
  contentCopy: standard(ContentCopy),
  cropOriginal: standard(CropOriginal),
  dashboard: standard(Dashboard),
  dateRange: standard(DateRange),
  delete: standard(Delete),
  deleteOutline: standard(DeleteOutline),
  description: standard(Description),
  desktopAccessDisabled: standard(DesktopAccessDisabled),
  deviceHub: standard(DeviceHub),
  done: standard(Done),
  doneAll: standard(DoneAll),
  drafts: standard(Drafts),
  eco: standard(Eco),
  edit: standard(Edit),
  email: standard(Email),
  errorOutline: standard(ErrorOutline),
  expandCircleDownIcon: standard(ExpandCircleDownIcon),
  expandCircleDownOutlinedIcon: standard(ExpandCircleDownOutlinedIcon),
  face: standard(Face),
  feedback: standard(Feedback),
  fileCopy: standard(FileCopy),
  filterList: standard(FilterList),
  filterListOff: standard(FilterListOff),
  fingerprint: standard(Fingerprint),
  flashOn: standard(FlashOn),
  flight: standard(Flight),
  fontDownload: standard(FontDownload),
  formatListBulleted: standard(FormatListBulleted),
  gavel: standard(Gavel),
  getApp: standard(GetApp),
  group: standard(Group),
  helpOutline: standard(HelpOutline),
  highlightOff: standard(HighlightOff),
  history: standard(HistoryIcon),
  home: standard(Home),
  howToReg: standard(HowToReg),
  image: standard(Image),
  info: standard(Info),
  insertPhoto: standard(InsertPhoto),
  keyboard: standard(Keyboard),
  keyboardArrowDown: standard(KeyboardArrowDown),
  keyboardArrowUp: standard(KeyboardArrowUp),
  landscape: standard(Landscape),
  launch: standard(Launch),
  lens: standard(Lens),
  link: standard(Link),
  list: standard(List),
  localLibrary: standard(LocalLibrary),
  locationCity: standard(LocationCity),
  locationOn: standard(LocationOn),
  lock: standard(Lock),
  lockOpen: standard(LockOpen),
  logout: standard(LogoutIcon),
  mailOutline: standard(MailOutline),
  meetingRoom: standard(MeetingRoom),
  menu: standard(Menu),
  menuBook: standard(MenuBook),
  message: standard(Message),
  moreVert: standard(MoreVert),
  mouse: standard(Mouse),
  movie: standard(Movie),
  myLocation: standard(MyLocation),
  networkCheck: standard(NetworkCheck),
  notifications: standard(Notifications),
  person: standard(Person),
  personOutline: standard(PersonOutline),
  phone: standard(Phone),
  phoneIphone: standard(PhoneIphone),
  pictureAsPdf: standard(PictureAsPdf),
  playArrow: standard(PlayArrow),
  playlistAddCheck: standard(PlaylistAddCheck),
  policy: standard(Policy),
  poll: standard(Poll),
  powerSettingsNew: standard(PowerSettingsNew),
  public: standard(Public),
  publish: standard(Publish),
  questionAnswerIcon: standard(QuestionAnswerIcon),
  radioButtonChecked: standard(RadioButtonChecked),
  radioButtonUnchecked: standard(RadioButtonUnchecked),
  recordVoiceOver: standard(RecordVoiceOver),
  refresh: standard(Refresh),
  reviews: standard(ReviewsIcon),
  removeModerator: standard(RemoveModerator),
  removeRedEye: standard(RemoveRedEye),
  safetyCheck: standard(SafetyCheck),
  satellite: standard(Satellite),
  save: standard(Save),
  scatterPlot: standard(ScatterPlot),
  search: standard(Search),
  send: standard(Send),
  security: standard(Security),
  selectAll: standard(SelectAllIcon),
  settings: standard(Settings),
  showChart: standard(ShowChart),
  sort: standard(Sort),
  star: standard(Star),
  stop: standard(Stop),
  store: standard(Store),
  supervisedUserCircleIcon: standard(SupervisedUserCircleIcon),
  supervisorAccount: standard(SupervisorAccount),
  swapHoriz: standard(SwapHoriz),
  swapVert: standard(SwapVert),
  textFields: standard(TextFields),
  textFormat: standard(TextFormat),
  thumbsUpDown: standard(ThumbsUpDown),
  tipsAndUpdates: standard(TipsAndUpdatesIcon),
  today: standard(Today),
  trackChanges: standard(TrackChanges),
  trendingUp: standard(TrendingUp),
  redo: standard(Redo),
  undo: standard(Undo),
  update: standard(Update),
  verifiedUser: standard(VerifiedUser),
  viewHeadline: standard(ViewHeadlineIcon),
  viewWeek: standard(ViewWeek),
  visibilityOff: standard(VisibilityOff),
  warning: standard(Warning),
  waves: standard(Waves),
  web: standard(Web),
  work: standard(Work),
};
