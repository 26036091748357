/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import { color as colorUtil } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { is } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { FONTS } from '../../common/constants.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { formatMarkdown } from './util.tsx';

export interface ITextStyle {
  color?: string | number;
  size?: string | number;
  align?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
  weight?: React.CSSProperties['fontWeight'];
  letterSpacing?: string | number;
  lineHeight?: string | number;
  uppercase?: boolean;
  ellipsis?: boolean;
  italic?: boolean;
  textShadow?: string | Array<string | number>; // [0:y-offset, 1:color.format()]
  background?: boolean | number | string;
}

export interface ITextProps extends ITextStyle {
  block?: boolean;
  isMarkdown?: boolean;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  opacity?: number;
  width?: string | number;
  selectable?: boolean;
  cursor?: React.CSSProperties['cursor'];
  tooltip?: string | React.ReactElement;
  tooltipArrow?: boolean;
  tooltipTheme?: 'DARK' | 'LIGHT';
  top?: string | number;
  style?: Interpolation<Theme>;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const colors: { [key: string]: string } = {
  white: '#fff',
  dark: '#4C4C4C',
  black: '#000',
  blue: COLORS.BLUE,
};

const toTextShadow = (
  value?: string | Array<string | number>,
): string | undefined => {
  if (value === undefined) {
    return;
  }
  if (is(String, value)) {
    return value as string;
  }
  return `0px ${value[0]}px ${colorUtil.format(value[1])}`;
};

/**
 * Text element encapsulating standard styling for the app.
 */
export const Text = ({
  children,
  width,
  letterSpacing,
  lineHeight,
  textShadow,
  opacity,
  background,
  align,
  color = 'dark',
  block = false,
  size = 16,
  weight = FontWeight.normal,
  ellipsis = false,
  italic = false,
  selectable = true,
  isMarkdown = false,
  cursor,
  uppercase = false,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  tooltip,
  tooltipArrow = false,
  tooltipTheme,
  top,
  style,
  className,
  onClick,
}: ITextProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const toIsOverflowing =
        Number(divRef.current?.offsetWidth) <
        Number(divRef.current?.scrollWidth);

      setIsOverflowing(toIsOverflowing);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [children]);

  const base = css({
    position: 'relative',
    display: block ? 'block' : 'inline-block',
    width,
    letterSpacing,
    lineHeight,
    opacity,
    color: colors[color] ?? colorUtil.format(color),
    backgroundColor: colorUtil.format(background),
    fontFamily: FONTS.Roboto.family,
    fontSize: size,
    fontWeight: weight,
    fontStyle: italic && 'italic',
    textAlign: align,
    textRendering: 'optimizeLegibility',
    userSelect: selectable ? null : 'none',
    cursor: cursor ?? (selectable ? 'text' : 'inherit'),
    textTransform: uppercase ? 'uppercase' : 'none',
    textShadow: toTextShadow(textShadow),
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    top,
    whiteSpace: ellipsis ? 'nowrap' : undefined,
    overflow: ellipsis ? 'hidden' : undefined,
    textOverflow: ellipsis ? 'ellipsis' : undefined,
  });

  const content = isMarkdown ? formatMarkdown(children) : children;

  const stringContent = is(String, content) ? (content as string) : undefined;

  const elText = (
    <div
      ref={divRef}
      className={className}
      css={[base, style]}
      onClick={onClick}
      data-test={stringContent ? `primitives-text-${stringContent}` : undefined}
    >
      {content}
    </div>
  );

  return tooltip || isOverflowing ? (
    <Tooltip
      title={tooltip ?? children?.toString()}
      arrow={tooltipArrow || isOverflowing}
      theme={tooltipTheme}
    >
      {elText}
    </Tooltip>
  ) : (
    elText
  );
};
