/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import {
  Button,
  ButtonClickEventHandler,
  IButtonProps,
  IInnerButtonProps,
} from '../Button/Button.tsx';
import { IIconProps } from '../Icon/types.ts';

export type IIconButtonProps = {
  dataTest?: string;
  icon: JSX.Element;
  buttonStyle?: CSSProperties;
  tooltip?: string;
  cursor?: string;
  style?: SerializedStyles;

  /* From Button props */
  onClick?: ButtonClickEventHandler;
  onMouseDown?: ButtonClickEventHandler;
  isEnabled?: IButtonProps['isEnabled'];
  tabIndex?: number;
  display?: React.CSSProperties['display'];
  centerRipple?: boolean;

  /* Icon Props */
  size?: number | string;
  fill?: IIconProps['fill'];
  disabledFill?: IIconProps['fill'];
};

/**
 * A button that depicts an icon.
 */
export const IconButton: React.FC<IIconButtonProps> = ({
  centerRipple = true,
  isEnabled = true,
  buttonStyle,
  cursor,
  display,
  fill,
  icon,
  onClick,
  onMouseDown,
  style,
  size,
  tabIndex,
  tooltip,
}) => {
  const innerButton = (
    <Inner
      isEnabled={isEnabled}
      icon={icon}
      size={size}
      fill={fill}
      cursor={cursor}
      style={style}
    />
  );

  const elButton = (
    <Button
      onClick={onClick}
      onMouseDown={onMouseDown}
      isEnabled={isEnabled}
      tabIndex={tabIndex}
      display={display}
      centerRipple={centerRipple}
      tooltip={tooltip}
      style={{ ...styles.button(fill), ...buttonStyle }}
    >
      {innerButton}
    </Button>
  );

  return elButton;
};

const styles = {
  button: (fill: string = COLORS.BLUE) => ({
    padding: 9,
    margin: -9,
    cursor: 'pointer',
    borderRadius: '100%',
    ...CommonStyles.MaterialCubicTransitions,
    color: color.create(fill).alpha(0.4).css(), // Changes the ripple color.
    transitionDuration: '150ms',
    '&:hover': {
      background: color.create(fill).alpha(0.04).css(),
    },
  }),
};

type IInnerProps = IInnerButtonProps & {
  dataTest?: string;
  icon: JSX.Element;
};

const Inner: React.FC<IInnerProps> = ({
  cursor: propsCursor,
  fill,
  icon,
  isEnabled,
  size,
  style,
}) => {
  const isDisabled = !isEnabled;

  const cursor = isDisabled ? 'normal' : propsCursor ?? 'pointer';

  const computedStyles = {
    base: css({
      display: 'flex',
      width: size,
      height: size,
    }),
  };

  const elClonedInner = React.cloneElement(icon, {
    ...icon.props,
    ...{
      size,
      fill,
      cursor,
    },
  });

  return <div css={[computedStyles.base, style]}>{elClonedInner}</div>;
};
