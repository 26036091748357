/** @jsxImportSource @emotion/react */
import React from 'react';
import * as R from 'ramda';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css, SerializedStyles } from '@emotion/react';

export interface ILeftStackProps {
  total?: number;
  style?: SerializedStyles;
  onClick?: () => void;
}

const LEAF_OFFSET = 5;
const clamp = (total = 0) => R.clamp(0, 3, total) || 0;

/**
 * A visual indicator that there are lists to the left.
 */
export class LeftStack extends React.Component<ILeftStackProps, {}> {
  public static width(total: number) {
    return clamp(total) * LEAF_OFFSET;
  }

  public render() {
    const length = clamp(this.props.total);
    const styles = {
      base: css({
        position: 'relative',
        width: LeftStack.width(length),
        cursor: 'pointer',
      }),
    };
    const elLeafs = Array.from({ length })
      .map((v, i) => {
        const left = i * LEAF_OFFSET;
        return <Leaf key={i} left={left} />;
      })
      .reverse();
    return (
      <div css={[styles.base, this.props.style]} onClick={this.props.onClick}>
        {elLeafs}
      </div>
    );
  }
}

const Leaf = (props: { left: number }) => {
  const styles = {
    base: css({
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: props.left,
      width: LEAF_OFFSET,
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRight: `solid 1px ${color.format(-0.2)}`,
      boxShadow: `0 0 6px 0 ${color.format(-0.3)}`,
    }),
  };
  return <div css={styles.base} />;
};
