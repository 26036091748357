/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Dot } from './components/Dot.tsx';
import { NavButton } from './components/NavButton.tsx';
import {
  IStatusDot,
  DotStatusNavClickEventHandler,
  DotStatusClickEventHandler,
} from './types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IDotStatusBarProps {
  items?: IStatusDot[];
  background?: number | string;
  dotColor?: number | string;
  style?: SerializedStyles;
  onPreviousClick?: DotStatusNavClickEventHandler;
  onNextClick?: DotStatusNavClickEventHandler;
  onDotClick?: DotStatusClickEventHandler;
  childTabIndex?: number;
}

/**
 * A set of visual dots in a bar that show status.
 */
export class DotStatusBar extends React.Component<IDotStatusBarProps, {}> {
  public static height = 22;

  public render() {
    const {
      dotColor = 1,
      background = -0.2,
      items = [],
      onPreviousClick,
      onNextClick,
      childTabIndex,
    } = this.props;
    const styles = {
      base: css({
        flex: '1 1 auto',
        backgroundColor: color.format(background),
        height: DotStatusBar.height,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden',
        cursor: 'default',
      }),
      dotsOuter: css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }),
      emptyNav: css({
        width: 24,
      }),
    };

    const elDots = items.map((item, i) => {
      return (
        <Dot
          key={i}
          index={i}
          type={item.type}
          color={dotColor}
          onClick={this.props.onDotClick}
        />
      );
    });

    const elPrevious = onPreviousClick && (
      <NavButton
        type={'PREVIOUS'}
        color={dotColor}
        onClick={onPreviousClick}
        tabIndex={childTabIndex}
      />
    );
    const elNext = onNextClick && (
      <NavButton
        type={'NEXT'}
        color={dotColor}
        onClick={onNextClick}
        tabIndex={childTabIndex}
      />
    );

    return (
      <div css={[styles.base, this.props.style]}>
        {elPrevious ?? <div css={styles.emptyNav} />}
        <div css={styles.dotsOuter}>{elDots}</div>
        {elNext ?? <div css={styles.emptyNav} />}
      </div>
    );
  }
}
