import * as R from 'ramda';
import {
  ISelectionListColumn,
  ISelectionListItem,
  ISelectionListSelection,
} from '../SelectionList/types.ts';

/**
 * Derives an ordered array of item ID's representing
 * the hierarchy-path from the root to the given selection ID.
 */
export function getColumnHierarchy(
  selectedId: string | number | undefined,
  rootColumn: ISelectionListColumn,
): ISelectionListColumn[] {
  if (!selectedId) {
    return [{ ...rootColumn, selectedIndex: -1 }]; // No selection.
  }

  // Retrieve the hierarchy of selections.
  const hierarchy = findSelection(selectedId, rootColumn) || [];
  if (hierarchy.length === 0) {
    return [{ ...rootColumn, selectedIndex: -1 }]; // No selection.
  }

  // Add the child column of the deepest selection.
  const deepest = hierarchy[hierarchy.length - 1];
  const deepestChildIndex =
    deepest && deepest.selectedIndex !== undefined ? deepest.selectedIndex : -1;
  const deepestChildren =
    deepestChildIndex > -1
      ? deepest.items[deepestChildIndex].children
      : undefined;
  const selectionHierarchy = updateSelectionHierarchyDeep(
    selectedId,
    hierarchy,
  );

  // Add the children of the deepest level.
  const result =
    deepestChildren !== undefined
      ? [...selectionHierarchy, { ...deepestChildren }]
      : selectionHierarchy;

  return result;
}

/**
 * Finds the item anywhere within the tree
 * corresponding to the given ID.
 */
function findSelection(
  id: string | number,
  column: ISelectionListColumn,
  parents: ISelectionListColumn[] = [],
): ISelectionListColumn[] | undefined {
  // Check column items.
  const selectedIndex = column.items.findIndex((item) => item.id === id);
  if (selectedIndex > -1) {
    return [...parents, { ...column, selectedIndex }];
  }

  // Check the children of each item.
  parents = [...parents, { ...column, selectedIndex: -1 }];
  for (const item of column.items) {
    if (item.children) {
      const selectedChild = findSelection(id, item.children, parents); // <= RECURSION.
      if (selectedChild) {
        return selectedChild;
      }
    }
  }
  return undefined; // Selection not found.
}

/**
 * Finds an item with the given ID within a column
 */
export function findItem(
  itemId: string | number | undefined,
  column: ISelectionListColumn,
): ISelectionListItem | undefined {
  return itemId
    ? column.items.find((item) =>
        Boolean(item.children?.items.some((child) => child.id === itemId)),
      )
    : undefined;
}

/**
 * Runs through a selection hierarchy ensuring each child in set
 * has the appropriate `isSelected` value.
 */
function updateSelectionHierarchyDeep(
  selectedId: string | number,
  hierarchy: ISelectionListColumn[],
) {
  if (hierarchy.length === 0) {
    return hierarchy;
  }
  hierarchy = [...hierarchy];

  const walk = (level: number, itemId: string | number) => {
    const column = hierarchy[level];
    const selectedIndex = column.items.findIndex((item) => {
      return item.id === itemId;
    });
    hierarchy[level] = { ...column, selectedIndex };

    // Walk up the hierarchy if not already at root.
    if (level > 0) {
      const parentColumn = hierarchy[level - 1];
      const parent = findItem(itemId, parentColumn);
      if (parent) {
        walk(level - 1, parent.id); // <= RECURSION.
      }
    }
  };

  walk(hierarchy.length - 1, selectedId);
  return hierarchy;
}

/**
 * Retrieves the deepest selection from the given list of columns.
 */
export function deepestSelection(
  columns: ISelectionListColumn[],
): ISelectionListSelection {
  const items = columns.map((column, columnIndex) => ({ column, columnIndex }));
  const match = R.reverse(items).find((item) => {
    const index = item.column.selectedIndex;
    return index !== undefined && index > -1;
  });
  return match ? toSelection(match.columnIndex, match.column) : BLANK_COLUMN;
}

export function toSelection(
  columnIndex: number,
  column?: ISelectionListColumn,
  itemIndex?: number,
): ISelectionListSelection {
  const index =
    itemIndex !== undefined
      ? itemIndex
      : column && column.selectedIndex !== undefined
        ? column.selectedIndex
        : -1;
  const item = column && column.items[index];
  return column ? { column: columnIndex, index, item } : BLANK_COLUMN; // Blank.
}
const BLANK_COLUMN: ISelectionListSelection = {
  column: -1,
  index: -1,
  item: undefined,
};
