import { marked } from 'marked';
import { getRenderer } from './renderer.ts';
import * as types from './types.ts';

export function markdown(src: string, options?: types.IMarkdownOptions) {
  const withDefaults = {
    // Custom.
    linksInNewTab: true,
    imageDimensions: true,

    // https://marked.js.org/
    gfm: true,
    breaks: true,
    tables: true,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: true,

    ...options,
  };

  const withRenderer = {
    ...withDefaults,
    renderer: getRenderer(withDefaults),
  };

  return marked(src, withRenderer);
}
