import { Subject } from 'rxjs';
const dynamics = require('dynamics.js');

export type AnimationTarget = HTMLElement | HTMLElement[] | object | object[];
export type AnimationType =
  | 'spring'
  | 'bounce'
  | 'forceWithGravity'
  | 'gravity'
  | 'easeInOut'
  | 'easeIn'
  | 'easeOut'
  | 'linear'
  | 'bezier';
export type AnimationChangeHandler = (
  el: AnimationTarget,
  progress: number,
) => void;

export interface IAnimationOptions {
  type: AnimationType;
  duration: number;
  frequency?: number;
  friction?: number;
  bounciness?: number;
  delay?: number;
  change?: AnimationChangeHandler;
}

export interface IAnimationObservableOptions<T> extends IAnimationOptions {
  target: T;
  current: () => T;
}

/**
 * Performs an animation returning an observable of animated values.
 *
 */
export function start$<T>(options: IAnimationObservableOptions<T>) {
  const { target } = options;
  const subject = new Subject();

  const obj = {};
  const define = (key: string) => {
    Object.defineProperty(obj, key, {
      get: () => (options.current() as any)[key],
      set: (value: any) => subject.next({ [key]: value }),
    });
  };
  Object.keys(target).forEach((key) => define(key));

  setTimeout(async () => {
    // NB:  Skip a tick to allow for the Observable to be
    //      returned before starting the animation.
    await start(obj, target, options);
    subject.complete();
  }, 0);
  return subject as Subject<T>;
}

/**
 * Animates an element/object to the given set of property values.
 * See:
 *    https://github.com/michaelvillar/dynamics.js#usage
 */
export function start<T>(
  target: T | HTMLElement,
  props: T,
  options: IAnimationOptions,
) {
  return new Promise<void>((resolve) => {
    const args = {
      ...options,
      type: dynamics[options.type],
      complete: () => resolve(),
    };
    dynamics.animate(target, props, args);
  });
}
